html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("roboto-v30-latin-300.d07a8413.woff2") format("woff2"), url("roboto-v30-latin-300.e1075c83.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("roboto-v30-latin-300italic.6557267a.woff2") format("woff2"), url("roboto-v30-latin-300italic.6aceee31.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("roboto-v30-latin-regular.57844c26.woff2") format("woff2"), url("roboto-v30-latin-regular.32450484.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("roboto-v30-latin-700.0e98339e.woff2") format("woff2"), url("roboto-v30-latin-700.df41cf3b.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("roboto-v30-latin-700italic.84bda3d4.woff2") format("woff2"), url("roboto-v30-latin-700italic.5e2bfc70.woff") format("woff");
}

*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: #606c76;
  letter-spacing: .01em;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.6;
}

blockquote {
  border-left: .3rem solid #d1d1d1;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}

blockquote :last-child {
  margin-bottom: 0;
}

.button, button, input[type="button"], [type="button"].file, input[type="reset"], [type="reset"].file, input[type="submit"], [type="submit"].file {
  color: #fff;
  cursor: pointer;
  height: 3.8rem;
  letter-spacing: .1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #9b4dca;
  border: .1rem solid #9b4dca;
  border-radius: .4rem;
  padding: 0 3rem;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 3.8rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus, .button:hover, button:focus, button:hover, input[type="button"]:focus, [type="button"].file:focus, input[type="button"]:hover, [type="button"].file:hover, input[type="reset"]:focus, [type="reset"].file:focus, input[type="reset"]:hover, [type="reset"].file:hover, input[type="submit"]:focus, [type="submit"].file:focus, input[type="submit"]:hover, [type="submit"].file:hover {
  color: #fff;
  background-color: #606c76;
  border-color: #606c76;
  outline: 0;
}

.button[disabled], button[disabled], input[type="button"][disabled], [type="button"][disabled].file, input[type="reset"][disabled], [type="reset"][disabled].file, input[type="submit"][disabled], [type="submit"][disabled].file {
  cursor: default;
  opacity: .5;
}

.button[disabled]:focus, .button[disabled]:hover, button[disabled]:focus, button[disabled]:hover, input[type="button"][disabled]:focus, [type="button"][disabled].file:focus, input[type="button"][disabled]:hover, [type="button"][disabled].file:hover, input[type="reset"][disabled]:focus, [type="reset"][disabled].file:focus, input[type="reset"][disabled]:hover, [type="reset"][disabled].file:hover, input[type="submit"][disabled]:focus, [type="submit"][disabled].file:focus, input[type="submit"][disabled]:hover, [type="submit"][disabled].file:hover {
  background-color: #9b4dca;
  border-color: #9b4dca;
}

.button.button-outline, button.button-outline, input[type="button"].button-outline, [type="button"].button-outline.file, input[type="reset"].button-outline, [type="reset"].button-outline.file, input[type="submit"].button-outline, [type="submit"].button-outline.file {
  color: #9b4dca;
  background-color: rgba(0, 0, 0, 0);
}

.button.button-outline:focus, .button.button-outline:hover, button.button-outline:focus, button.button-outline:hover, input[type="button"].button-outline:focus, [type="button"].button-outline.file:focus, input[type="button"].button-outline:hover, [type="button"].button-outline.file:hover, input[type="reset"].button-outline:focus, [type="reset"].button-outline.file:focus, input[type="reset"].button-outline:hover, [type="reset"].button-outline.file:hover, input[type="submit"].button-outline:focus, [type="submit"].button-outline.file:focus, input[type="submit"].button-outline:hover, [type="submit"].button-outline.file:hover {
  color: #606c76;
  background-color: rgba(0, 0, 0, 0);
  border-color: #606c76;
}

.button.button-outline[disabled]:focus, .button.button-outline[disabled]:hover, button.button-outline[disabled]:focus, button.button-outline[disabled]:hover, input[type="button"].button-outline[disabled]:focus, [type="button"].button-outline[disabled].file:focus, input[type="button"].button-outline[disabled]:hover, [type="button"].button-outline[disabled].file:hover, input[type="reset"].button-outline[disabled]:focus, [type="reset"].button-outline[disabled].file:focus, input[type="reset"].button-outline[disabled]:hover, [type="reset"].button-outline[disabled].file:hover, input[type="submit"].button-outline[disabled]:focus, [type="submit"].button-outline[disabled].file:focus, input[type="submit"].button-outline[disabled]:hover, [type="submit"].button-outline[disabled].file:hover {
  border-color: inherit;
  color: #9b4dca;
}

.button.button-clear, button.button-clear, input[type="button"].button-clear, [type="button"].button-clear.file, input[type="reset"].button-clear, [type="reset"].button-clear.file, input[type="submit"].button-clear, [type="submit"].button-clear.file {
  color: #9b4dca;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.button.button-clear:focus, .button.button-clear:hover, button.button-clear:focus, button.button-clear:hover, input[type="button"].button-clear:focus, [type="button"].button-clear.file:focus, input[type="button"].button-clear:hover, [type="button"].button-clear.file:hover, input[type="reset"].button-clear:focus, [type="reset"].button-clear.file:focus, input[type="reset"].button-clear:hover, [type="reset"].button-clear.file:hover, input[type="submit"].button-clear:focus, [type="submit"].button-clear.file:focus, input[type="submit"].button-clear:hover, [type="submit"].button-clear.file:hover {
  color: #606c76;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.button.button-clear[disabled]:focus, .button.button-clear[disabled]:hover, button.button-clear[disabled]:focus, button.button-clear[disabled]:hover, input[type="button"].button-clear[disabled]:focus, [type="button"].button-clear[disabled].file:focus, input[type="button"].button-clear[disabled]:hover, [type="button"].button-clear[disabled].file:hover, input[type="reset"].button-clear[disabled]:focus, [type="reset"].button-clear[disabled].file:focus, input[type="reset"].button-clear[disabled]:hover, [type="reset"].button-clear[disabled].file:hover, input[type="submit"].button-clear[disabled]:focus, [type="submit"].button-clear[disabled].file:focus, input[type="submit"].button-clear[disabled]:hover, [type="submit"].button-clear[disabled].file:hover {
  color: #9b4dca;
}

code {
  white-space: nowrap;
  background: #f4f5f6;
  border-radius: .4rem;
  margin: 0 .2rem;
  padding: .2rem .5rem;
  font-size: 86%;
}

pre {
  background: #f4f5f6;
  border-left: .3rem solid #9b4dca;
  overflow-y: hidden;
}

pre > code {
  white-space: pre;
  border-radius: 0;
  padding: 1rem 1.5rem;
  display: block;
}

hr {
  border: 0;
  border-top: .1rem solid #f4f5f6;
  margin: 3rem 0;
}

input[type="color"], [type="color"].file, input[type="date"], [type="date"].file, input[type="datetime"], [type="datetime"].file, input[type="datetime-local"], [type="datetime-local"].file, input[type="email"], [type="email"].file, input[type="month"], [type="month"].file, input[type="number"], [type="number"].file, input[type="password"], [type="password"].file, input[type="search"], [type="search"].file, input[type="tel"], [type="tel"].file, input[type="text"], [type="text"].file, input[type="url"], [type="url"].file, input[type="week"], [type="week"].file, input:not([type]), .file:not([type]), textarea, select {
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: .1rem solid #d1d1d1;
  border-radius: .4rem;
  padding: .6rem 1rem .7rem;
}

input[type="color"]:focus, [type="color"].file:focus, input[type="date"]:focus, [type="date"].file:focus, input[type="datetime"]:focus, [type="datetime"].file:focus, input[type="datetime-local"]:focus, [type="datetime-local"].file:focus, input[type="email"]:focus, [type="email"].file:focus, input[type="month"]:focus, [type="month"].file:focus, input[type="number"]:focus, [type="number"].file:focus, input[type="password"]:focus, [type="password"].file:focus, input[type="search"]:focus, [type="search"].file:focus, input[type="tel"]:focus, [type="tel"].file:focus, input[type="text"]:focus, [type="text"].file:focus, input[type="url"]:focus, [type="url"].file:focus, input[type="week"]:focus, [type="week"].file:focus, input:not([type]):focus, .file:not([type]):focus, textarea:focus, select:focus {
  border-color: #9b4dca;
  outline: 0;
}

select {
  background: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 8\" width=\"30\"><path fill=\"%23d1d1d1\" d=\"M0,0l6,8l6-8\"/></svg>") 100% no-repeat;
  padding-right: 3rem;
}

select:focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 8\" width=\"30\"><path fill=\"%239b4dca\" d=\"M0,0l6,8l6-8\"/></svg>");
}

select[multiple] {
  height: auto;
  background: none;
}

textarea {
  min-height: 6.5rem;
}

label, legend {
  margin-bottom: .5rem;
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type="checkbox"], [type="checkbox"].file, input[type="radio"], [type="radio"].file {
  display: inline;
}

.label-inline {
  margin-left: .5rem;
  font-weight: normal;
  display: inline-block;
}

.container {
  max-width: 112rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.row {
  width: 100%;
  flex-direction: column;
  padding: 0;
  display: flex;
}

.row.row-no-padding, .row.row-no-padding > .column {
  padding: 0;
}

.row.row-wrap {
  flex-wrap: wrap;
}

.row.row-top {
  align-items: flex-start;
}

.row.row-bottom {
  align-items: flex-end;
}

.row.row-center {
  align-items: center;
}

.row.row-stretch {
  align-items: stretch;
}

.row.row-baseline {
  align-items: baseline;
}

.row .column {
  max-width: 100%;
  width: 100%;
  flex: auto;
  margin-left: 0;
  display: block;
}

.row .column.column-offset-10 {
  margin-left: 10%;
}

.row .column.column-offset-20 {
  margin-left: 20%;
}

.row .column.column-offset-25 {
  margin-left: 25%;
}

.row .column.column-offset-33, .row .column.column-offset-34 {
  margin-left: 33.3333%;
}

.row .column.column-offset-40 {
  margin-left: 40%;
}

.row .column.column-offset-50 {
  margin-left: 50%;
}

.row .column.column-offset-60 {
  margin-left: 60%;
}

.row .column.column-offset-66, .row .column.column-offset-67 {
  margin-left: 66.6666%;
}

.row .column.column-offset-75 {
  margin-left: 75%;
}

.row .column.column-offset-80 {
  margin-left: 80%;
}

.row .column.column-offset-90 {
  margin-left: 90%;
}

.row .column.column-10 {
  max-width: 10%;
  flex: 0 0 10%;
}

.row .column.column-20 {
  max-width: 20%;
  flex: 0 0 20%;
}

.row .column.column-25 {
  max-width: 25%;
  flex: 0 0 25%;
}

.row .column.column-33, .row .column.column-34 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row .column.column-40 {
  max-width: 40%;
  flex: 0 0 40%;
}

.row .column.column-50 {
  max-width: 50%;
  flex: 0 0 50%;
}

.row .column.column-60 {
  max-width: 60%;
  flex: 0 0 60%;
}

.row .column.column-66, .row .column.column-67 {
  max-width: 66.6666%;
  flex: 0 0 66.6666%;
}

.row .column.column-75 {
  max-width: 75%;
  flex: 0 0 75%;
}

.row .column.column-80 {
  max-width: 80%;
  flex: 0 0 80%;
}

.row .column.column-90 {
  max-width: 90%;
  flex: 0 0 90%;
}

.row .column .column-top {
  align-self: flex-start;
}

.row .column .column-bottom {
  align-self: flex-end;
}

.row .column .column-center {
  align-self: center;
}

@media (min-width: 40rem) {
  .row {
    width: calc(100% + 2rem);
    flex-direction: row;
    margin-left: -1rem;
  }

  .row .column {
    margin-bottom: inherit;
    padding: 0 1rem;
  }
}

a {
  color: #9b4dca;
  text-decoration: none;
}

a:focus, a:hover {
  color: #606c76;
}

dl, ol, ul {
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}

dl dl, dl ol, dl ul, ol dl, ol ol, ol ul, ul dl, ul ol, ul ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}

ol {
  list-style: decimal inside;
}

ul {
  list-style: circle inside;
}

.button, button, dd, dt, li {
  margin-bottom: 1rem;
}

fieldset, input, .file, select, textarea {
  margin-bottom: 1.5rem;
}

blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-bottom: 2.5rem;
}

table {
  border-spacing: 0;
  text-align: left;
  width: 100%;
  display: block;
  overflow-x: auto;
}

td, th {
  border-bottom: .1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

@media (min-width: 40rem) {
  table {
    overflow-x: initial;
    display: table;
  }
}

b, strong {
  font-weight: bold;
}

p {
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.1rem;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  letter-spacing: -.08rem;
  font-size: 2.2rem;
  line-height: 1.35;
}

h5 {
  letter-spacing: -.05rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

h6 {
  letter-spacing: 0;
  font-size: 1.6rem;
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

body {
  background-color: #f3f3f3;
}

.app {
  background-color: #fff;
  margin: 30px auto;
  padding: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .15), 0 2px 2px rgba(0, 0, 0, .15), 0 4px 4px rgba(0, 0, 0, .15), 0 8px 8px rgba(0, 0, 0, .15);
}

.notice {
  color: #655d5d;
  background: #f4f5f6;
  border: 0;
  border-left: .3rem solid #9b4dca;
  padding: 1rem 1.5rem;
  overflow-y: hidden;
}

.notice.error {
  border-left-color: red;
}

.hidden {
  display: none;
}

.file {
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: .1rem solid #d1d1d1;
  border-radius: .4rem;
  padding: .6rem 1rem .7rem;
  display: block;
  position: relative;
}

.file:focus, .file:hover {
  cursor: pointer;
}

.file input, .file .file {
  width: 100%;
}

.file input:focus, .file .file:focus, .file input:hover, .file .file:hover {
  cursor: pointer;
}

.file ::-webkit-file-upload-button, .file ::file-selector-button {
  color: #fff;
  background: #cda6e5;
  border: none;
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 5px 15px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.file ::-webkit-file-upload-button:focus, .file ::-webkit-file-upload-button:hover, .file ::file-selector-button:focus, .file ::file-selector-button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #606c76;
  border-color: #606c76;
  outline: 0;
}

video {
  width: 100%;
  max-width: 100%;
}

#bounds {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
}

#area {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  box-sizing: border-box;
  resize: both;
  overflow-wrap: anywhere;
  cursor: move;
  background-color: rgba(0, 0, 0, .33);
  outline: 2.5px dashed #949494;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

#area-txt {
  color: rgba(255, 255, 255, .6);
  transition: color .25s linear 1s;
}

#area:is(:hover, :focus) #area-txt {
  color: #fff;
  transition: color .25s linear;
}

#loader {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border: 5px solid #f3f3f3;
  border-top-color: #555;
  border-radius: 50%;
  margin-left: 15px;
  animation: spin 1s linear infinite;
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#progress {
  margin-left: 7px;
}

#video-input {
  max-width: 100%;
  background-color: #655d5d;
}

#video-output {
  display: none;
}

/*# sourceMappingURL=index.355f33e0.css.map */
