@import '../css/normalize.css';
@import '../css/fonts.css';
@import 'milligram';



// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #9b4dca !default;
$color-secondary: #606c76 !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;

body {
  background-color: #f3f3f3;
}

.app {
  margin: 30px auto;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}

.notice {
  border: 0;
  border-left: 0.3rem solid #9b4dca;
  color: #655d5d;
  background: #f4f5f6;
  border-left: 0.3rem solid #9b4dca;
  overflow-y: hidden;
  padding: 1rem 1.5rem;

  &.error {
    border-left-color: red;
  }
}

.hidden {
  display: none;
}

.file {
  @extend input;

  display: block;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  border-radius: 0.4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: 0.6rem 1rem 0.7rem;
  width: 100%;
  position: relative;

  &:focus,
  &:hover {
    cursor: pointer;
  }

  input {
    width: 100%;

    &:focus,
    &:hover {
      cursor: pointer;
    }

  }

  ::-webkit-file-upload-button,
  ::file-selector-button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #cda6e5;
    color: #fff;
    padding: 5px 15px;
    display: flex;
    align-content: center;
    align-items: center;
    margin: 0;

    &:focus,
    &:hover {
      background-color: $color-secondary;
      border-color: $color-secondary;
      color: $color-initial;
      outline: 0;
      cursor: pointer;
    }
  }
}

video {
  width: 100%;
  max-width: 100%;
}

#bounds {
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: 100%;
}

#area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 1;
  outline: dashed #949494 2.5px;
  box-sizing: border-box;
  resize: both;
  overflow: auto;
  display: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-wrap: anywhere;
  cursor: move;
}

#area-txt {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.25s linear 1s;
}

#area:is(:hover, :focus) #area-txt {
  color: white;
  transition: color 0.25s linear;
}

#loader {
  display: none;
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#progress {
  margin-left: 7px;
}

#video-input {
  max-width: 100%;
  background-color: #655d5d;
}

#video-output {
  display: none;
}
